import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Descriptor from "../../components/Descriptor";
import Image from "../../components/image.js";
import InnerContainer from "../../components/InnerContainer";

const DiagramComponent = styled.div`
  padding: 4rem 1.5rem;
  background-color: pink;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 6rem;
  }
`;

const DiagramTitle = styled.h2`
  margin-top: 2.5rem;
  font-family: ${props => props.theme.fonts.medium};
  font-size: 4rem;
  line-height: 50px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 6.5rem;
    line-height: 78px;
  }
`;

const MobileImage = styled.div`
  margin-top: 4rem;
  align-self: center;
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;

const DesktopImage = styled.div`
  display: none;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
    justify-content: center;
    margin-top: 5.5rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Diagram = props => {
  return (
    <DiagramComponent
      css={css`
        background-color: ${props.backgroundColor};
      `}
    >
      <InnerContainer>
        <FlexContainer>
          <Descriptor
            title={props.descriptor}
            color={props.descriptorColor}
            alignment={props.underlineAlignment}
          />
          <DiagramTitle
            css={css`
              color: ${props.titleColor};
            `}
          >
            {props.title}
          </DiagramTitle>

          <MobileImage>
            <Image image={props.diagramMobile} />
          </MobileImage>
          <DesktopImage>
            <Image image={props.diagramDesktop} />
          </DesktopImage>
        </FlexContainer>
      </InnerContainer>
    </DiagramComponent>
  );
};

export default Diagram;
