import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Image from "../../components/image";
import Descriptor from "../../components/Descriptor";
import InnerContainer from "../../components/InnerContainer";

const TextBlocksComponent = styled.div`
  background-color: ${props => props.theme.colors.lightPeach};
  padding: 3.5rem 1.5rem;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 6rem;
  }
`;

const FlexContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
  }
`;

const LeftContainer = styled.div`
  width: 17rem;
  max-width: 17rem;
`;

const TextLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    align-items: center;
  } ;
`;

const Icon = styled(Image)`
  width: 5.45rem;
  height: 5.45rem;
  margin-top: 3rem;
`;

const RightContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 8rem;
    width: 57.62%;
  }
`;

const TextBlocksTitle = styled.h2`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 4rem;
  line-height: 50px;
  margin-top: 2rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: unset;
    font-size: 6.5rem;
    line-height: 80px;
  }
`;

const TextBlocksParagraph = styled.p`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 2.3rem;
  line-height: 35px;
  color: ${props => props.theme.colors.offBlack};
  margin-top: 3.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2.5rem;
    line-height: 39px;
    margin-top: 4.6rem;
  }
`;

const TextBlocksSubtitle = styled.h4`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 2.5rem;
  line-height: 32px;
  letter-spacing: 0.75px;
  margin-top: 2rem;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 3rem;
  }
`;

const TextBlocksSubtitleParagraph = styled.p`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 1.8rem;
  line-height: 29px;
  color: ${props => props.theme.colors.offBlack};
  margin-top: 1.5rem;
`;

const TextBlocksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 3.5rem;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 13px;
  }
`;

const TextBlock = styled.div`
  background-color: ${props => props.theme.colors.offWhite};
  padding: 2.5rem 3rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 3.5rem 3.8rem;
  }

  h4 {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 2.5rem;
    line-height: 32px;
    letter-spacing: 0.75px;
    margin-bottom: 1.2rem;
  }

  p {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.offBlack};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.light};
    }
  }
`;

const TextBlocks = props => {
  // console.log(props);
  return (
    <TextBlocksComponent>
      <InnerContainer>
        <FlexContainer>
          <LeftContainer>
            <TextLeftContainer>
              <Descriptor
                title={props.descriptor}
                color={props.descriptorColor}
                alignment={props.underlineAlignment}
              />
              {props.icon && <Icon image={props.icon} />}
            </TextLeftContainer>
          </LeftContainer>
          <RightContainer>
            <TextBlocksTitle
              css={css`
                color: ${props.titleColor};
              `}
            >
              {props.title}
            </TextBlocksTitle>
            <TextBlocksParagraph>{props.paragraph}</TextBlocksParagraph>
            <TextBlocksSubtitle
              css={css`
                color: ${props.subtitleColor};
              `}
            >
              {props.subtitle}
            </TextBlocksSubtitle>
            <TextBlocksSubtitleParagraph>
              {props.subtitleParagraph}
            </TextBlocksSubtitleParagraph>
            <TextBlocksContainer>
              {props.textBlocks.map((textblock, i) => (
                <TextBlock key={i}>
                  <h4
                    css={css`
                      color: ${textblock.titleColor};
                    `}
                  >
                    {textblock.title}
                  </h4>
                  <p>{textblock.paragraph}</p>
                </TextBlock>
              ))}
            </TextBlocksContainer>
          </RightContainer>
        </FlexContainer>
      </InnerContainer>
    </TextBlocksComponent>
  );
};

export default TextBlocks;
